import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import { Link } from 'gatsby'
import Header from "../components/header/header"
import animation from "./../animations/validated.json";
import { FaAngleRight } from 'react-icons/fa';

const Success = () => {
  let animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy(); 
  }, []);
return(
  <div>
    <Header />
    <div className="flex justify-center mt-10 h-screen">
    <div className="w-80 text-center">
    <div className="animation-container" ref={animationContainer} />
    <span className="block">Le message a bien été envoyé. Nous vous recontacterons dès que possible.</span>
    <Link to="/">
        <button className="w-fit shadow-md rounded-full bg-blue-900 text-slate-100 p-2 text-center mt-4 font-semibold">
          <div className="flex items-center">
            <span>retour à la page d'accueil</span><FaAngleRight />
          </div>
        </button>
      </Link>
    </div>
    </div>
  </div>
)}

export default Success;